<script>
  export let componentData = null;
  export let dividerAbove = false;
  export let dividerBelow = false


  let testimonialData;
  testimonialData = {
    titleTop: '',
    titleBottom: '',
    descriptionText: ''
  }


  if(componentData){
    console.log('Testemonial data');
    console.log(JSON.stringify(componentData));

    testimonialData = {
      titleTop: componentData.Year? componentData.Year : '',
      titleBottom: componentData.Author? componentData.Author : '',
      descriptionText: componentData.Text? componentData.Text : ''
    }
  }



</script>

<style lang="scss">

  .testimonial {
    margin-top: 65px;
    margin-bottom: 15px;

    @media screen and (min-width: 750px) and (max-width: 1023px) {
      margin-top: 80px;
      margin-bottom: 80px;
    }

    @media screen and (min-width: 1024px) {
      margin-top: 120px;
      margin-bottom: 120px;
    }
  }
  .testimonial__head:after {
    content: '';
    display: block;
  }

  .testimonial__head:has(h6:empty) { display: none; }

  .testimonial__body {
    @media screen and (min-width: 750px) {
      &:before { content: ''; }
    }
  }
  .testimonial__body blockquote { margin-top: 0; }
</style>

<section class="section testimonial"
  class:divider-above={dividerAbove}
  class:divider-below={dividerBelow}>   
  <div class="wrapper">
    <div class="testimonial__head grid grid--2">
      <h6>{testimonialData.titleTop}</h6>
    </div>

    <div class="testimonial__body grid grid--2 align-bottom">
      <div>
        <blockquote>
          {testimonialData.descriptionText}
        </blockquote>
        <h6>
         {@html testimonialData.titleBottom}  <!-- need to add <br/> to separate the text on 2 rows -->
        </h6>
      </div>
    </div>
  </div>
</section>